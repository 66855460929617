import { navigate, useStaticQuery, graphql } from 'gatsby'
import { AccordionItem, Accordion, Button, TextInput } from 'carbon-components-react';
import React, { useContext, useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'

function FAQS ({pageContext, location}) {
  const data = useStaticQuery(graphql`
  {
    allNodeParameters {
      nodes {
        field_app_faqs {
          value
        }
      }
    }
  }
`)
  const [pquery, setQuery] = useState('');
  const handleXClick = () => setQuery('')
  let mainFaqsRegex = new RegExp(pquery, "i")
  let parsedData = data.allNodeParameters.nodes[0].field_app_faqs
  ? ReactHtmlParser(data.allNodeParameters.nodes[0].field_app_faqs.value)
  : ''
  let answers = []
  for (let index = 0; index < parsedData.length; index++) {
    if (index === 0 || index%2 === 0) {
      answers[index]=(parsedData[index] 
        ? parsedData[index].props.children[0].props.children[0]
        : parsedData[index])
    } else {
      answers[index]=(parsedData[index].props.children[0])
    }
  }
  // ->> grouping as question-answer 
  //    so regex would work correctly ->>
  let pairs = [];
  for (var i=0 ; i<answers.length ; i+=2) {
      if (answers[i+1] !== undefined) {
          pairs.push (answers[i]+" "+answers[i+1]);
      } else {
          pairs.push (answers[i]);
      }
  }

  const questions = pairs ? pairs : []
  const [mainFaqsShown, setMainFaqsShown] = useState([...questions])
  const filterQuestions = () => {
    if (pquery.length > 0) {
      let newFaqs = [...questions].filter((quest) =>
        mainFaqsRegex.test(quest)
      );
      setMainFaqsShown(newFaqs);
    } else if (pquery.length === 0) {
      setMainFaqsShown([...questions]);
    }
  };
  useEffect(() => {
    filterQuestions()
  }, [pquery])
  function handleInputChange (event) {
    const target = event.target;
    const value = target.value;
    switch(target.id) {
      case 'faqsinput':
        setQuery(value)
        break
      default:
        console.log('field has no state')
    }
  }
  const FAQLayout = (
    <div>
      <div>
        <h3 className="faqtitle">FREQUENTLY ASKED QUESTIONS</h3>
        <br/>
      </div>
      <div className="search-bar-div">
            <TextInput 
                light
                type="text"
                key="faqsinput"
                id="faqsinput"
                maxLength={60}
                value={pquery}
                placeholder={"Search topics"}
                onChange={handleInputChange}
            />
            <Button 
            onClick={handleXClick}
            >Clear
            </Button>
            <br/>
        </div>
            <br/>
        <div className="faqsansw">
        <Accordion>
        {mainFaqsShown.map(faq => {
          return <AccordionItem className="faqsanswers"
          title={ faq.split('A.')[0] ? faq.split('A.')[0] : faq }
          >
            {faq.split('?')[1]}
            </AccordionItem>
        })}
        </Accordion>
        </div>
    </div>
  );
  return FAQLayout;
}
export default function FAQPage({ pageContext, location }) {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "HEED Retreat FAQs", tabs: ["Profile", "FAQS"] },
  };
  return (
    <FAQS
      pageContext={pageContext}
      location={location}
    />
  );
}
